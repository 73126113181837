import React from 'react';
import './PlayerHealthContextPanel.css'
import svg_confirm from './icons/success.svg';
import svg_cancel from './icons/cancel.svg'
import plus_health from './icons/plus.svg';
import minus_health from './icons/minus.svg';


class PlayerHealthContextPanel extends React.Component {

    constructor(props)
    {
        super(props);
    }

    closeMe(cancel)
    {
        if(cancel)
        {
            this.props.close();
        }else{
            this.props.close();
        }
    }

    render(){
        return(
            <div className={"playerHealthContextPanel flexcolumn" + (this.props.hidden ? " hidden" : "")}>
              <div>Player Context</div>
                <div>Set Poison Counters</div>
                <div className="damageRow flexrow">
                    <button className="healthButton"
                    onPointerUp={this.props.decreasePoison}>
                        <img src={minus_health} alt={"Decrease Poison"} draggable="false" />
                    </button>
                    <button className="counterButton">
                        {this.props.poison}
                    </button>
                    <button className="healthButton"
                    onPointerUp={this.props.increasePoison}>
                    <img src={plus_health} alt={"Increase Poison"}  draggable="false" />
                    </button>
                </div>
                <div className="confirmRow flexrow">
                    <button className='button cancelbutton' onClick={this.closeMe.bind(this,true)}><img alt='Cancel' src={svg_cancel}/></button>
                    <button className='button successbutton' onClick={this.closeMe.bind(this,false)}><img alt='Confirm' src={svg_confirm}/></button>
                </div>
            </div>
        );
    }
}


export default PlayerHealthContextPanel;
