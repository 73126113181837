import React from 'react';
import './MenuPanel.css';
import D20 from './D20.js'
import settings from './icons/settings.svg'
import spinner from './icons/spinner.svg'

class MenuPanel extends React.Component {

    render ()
    {
        return (
            <div className={"menupanel players" + this.props.playerCount}>
                <button className="menuPanelButton" onClick={this.props.showSpinnerPanel.bind(this)}>
                <div className="flexcolumn">
                        <img height="100%" alt="Spinner" src={spinner}/>
                    </div>
                </button>
                <button className="menuPanelButton" onClick={this.props.quickPick.bind(this)}>
                    Quick Pick
                </button>
                <D20 />
                <button className="menuPanelButton" onClick={this.props.showMassDamagePanel}>Mass Dmg</button>
                <button className="menuPanelButton" onClick={this.props.showMenu}>
                    <div className="flexcolumn">
                        <img height="100%" alt="Settings" src={settings}/>
                    </div>
                </button>
            </div>
        )
    }
}

export default MenuPanel;
