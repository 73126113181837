import React from 'react';
import './PlayerSector.css';
import CommanderUsed from './CommanderUsed.js'
import plus_health from './icons/plus.svg';
import minus_health from './icons/minus.svg';
import poison from './icons/poison.svg';

class PlayerSector extends React.Component {
 
    constructor(props)
    {
      super(props);      

      var selectedCommander = {};
      selectedCommander.playerId = null;
      selectedCommander.commanderId = null;

      this.state = {
        playerNo: parseInt(this.props.playerId + 1),
        healthAdjustRepeatTimer:null,
        damagePopupTimer:null,
        popupVisible:false,
        selectedCommander: {},
        startingHealth:this.props.startingHealth,
        currentDamage:0
      };

      
      window.addEventListener("mouseup",this.stopDamage.bind(this));
      window.addEventListener("touchend", this.stopDamage.bind(this));
    }
    
    startAdjustHealthMouse(player, value)
    {
      if(window.USER_TOUCH_ENABLED) return;
      clearTimeout(this.state.healthAdjustRepeatTimer);
      this.startAdjustHealth.bind(this,player, value, null)();
    }

    startAdjustHealth(player,value)
    {
      
      clearTimeout(this.state.healthAdjustRepeatTimer);
      this.adjustHealth.bind(this, player, value, null)();
      this.setState({healthAdjustRepeatTimer : setTimeout(this.adjustHealth.bind(this,player,value,150),800)})
    }

    stopDamage()
    {
      clearTimeout(this.state.damagePopupTimer);
      clearTimeout(this.state.healthAdjustRepeatTimer);
      this.setState({damagePopupTimer : setTimeout(this.hidePopup.bind(this),3000)});
    }

    hidePopup()
    {
      var selectedCommander = {};
      selectedCommander.playerId=null;
      selectedCommander.commanderId=null;
      this.setState({currentDamage:0, popupVisible : false, selectedCommander: selectedCommander});
    }

    adjustHealth(player, value, interval)
    {  
      
      if(this.state.selectedCommander.playerId != null)
      {
        this.props.adjustCommanderDamage(player,this.state.selectedCommander.playerId,this.state.selectedCommander.commanderId,value);
      }
      this.props.adjustHealth(player,value);
      this.setState({currentDamage: (this.state.currentDamage - value), popupVisible: true});
      clearTimeout(this.state.damagePopupTimer);
      if(interval != null)
      {
        clearTimeout(this.state.healthAdjustRepeatTimer);
        this.setState({healthAdjustRepeatTimer : setTimeout(this.adjustHealth.bind(this,player,value, interval-1),interval -1)})
      }
    }

    setSelectedCommander(playerId, commanderId)
    {
        this.hidePopup();
        clearTimeout(this.state.damagePopupTimer);
        var selectedCommander = this.state.selectedCommander;
        var newSelectedCommander = {};
        if(selectedCommander.playerId === playerId && selectedCommander.commanderId === commanderId)
        {
          newSelectedCommander.playerId = null;
          newSelectedCommander.commanderId = null;
        }else{
          newSelectedCommander.playerId = playerId;
          newSelectedCommander.commanderId = commanderId;
        }
        this.setState({selectedCommander: newSelectedCommander, damagePopupTimer : setTimeout(this.hidePopup.bind(this),3000)});
    }

    render()
    {
      var playerId = this.props.playerId;
      var playerNo = playerId +1;
      var player = this.props.players[playerId]
      var maxPlayers = this.props.maxPlayers;
    
      var highlighted = player.highlighted;

      var commanderDamagePanel = [];
      var commanderDamagePlayerPanel = [];


      for(var i=0;i<maxPlayers;i++)
      {
        commanderDamagePlayerPanel = [];

        var commanderDamage = player.commanderDamage[i].commanders[0];
        commanderDamagePlayerPanel.push((
            <button 
            key={i + "_" + 0}
            className={"commanderDamagePanel player" + (i + 1 ) + ((this.state.selectedCommander.playerId === i && this.state.selectedCommander.commanderId === 0) ? " selected" : "") + (commanderDamage > 16 ? " pulsing" : "")}
            onClick={this.setSelectedCommander.bind(this,i, 0)}>
                  {commanderDamage}
            </button>
        ));

        if(this.props.players[i].hasPartnerCommander){
            commanderDamage = player.commanderDamage[i].commanders[1];
            commanderDamagePlayerPanel.push((
              <button 
              key={i + "_" + 1}
              className={"commanderDamagePanel player" + (i + 1 ) + ((this.state.selectedCommander.playerId === i && this.state.selectedCommander.commanderId === 1) ? " selected" : "") + (commanderDamage > 16 ? " pulsing" : "")}
              onClick={this.setSelectedCommander.bind(this,i, 1)}>
                    {commanderDamage}
              </button>
          ));
        }
        
        commanderDamagePanel.push((
            <div key={i}>
              {commanderDamagePlayerPanel}
            </div>
          ));
      }

      
      var commanderUsedPanel = [];

      
      commanderUsedPanel.push((
        <CommanderUsed key={0} className={"player" + (playerId + 1)} playerId={playerId} commanderId={0} adjustCommanderUsed={this.props.adjustCommanderUsed} commanderUsed={player.commanders[0].used}/>
      ));

      if(player.hasPartnerCommander) {
         commanderUsedPanel.push((
            <CommanderUsed key={1} className={"player" + (playerId + 1)} playerId={playerId} commanderId={1} adjustCommanderUsed={this.props.adjustCommanderUsed} commanderUsed={player.commanders[1].used}/>
         ));
      }

      var currentHealth = (this.props.startingHealth - player.damage).toString();
      var counterclass = "onedigit";

      if(currentHealth.length === 2){
        //two digits
        counterclass = "twodigits";
      }else if(currentHealth.length === 3){
        //three digits
        counterclass = "threedigits";
      }else if(currentHealth.length === 4) {
        //four digits
        counterclass = "fourdigits";
      }else if(currentHealth.length >= 5){
        //more
        counterclass = "moredigits";
      }
  

  
      return (
        <div className={"playerSector flexrow player" + playerNo + (highlighted ? " highlighted" : "") } id={"sector" + playerNo + "of" + maxPlayers}>
          <div className="flexcolumn">
            <div className="subcolumn">
              <div className="topPanel">
                <div className="topPanelLeft"><div className={"poisonPanel " + (player.poison <=0 ? "hidden" : "")}><img src={poison} alt="Poison"></img>{player.poison}</div></div>
                <div className="popupwrapper " id={"sector" + playerNo + "of" + maxPlayers + "popupwrapper"}>
                  {this.state.popupVisible ? "" : player.name}
                  <div className={"popup" + (this.state.popupVisible ? "" : " hidden")} id={"sector" + playerNo + "of" + maxPlayers + "popup"}>
                    <div className="flexcolumn">
                      {(this.state.currentDamage > 0 ? "+" : "") + this.state.currentDamage}
                    </div>
                  </div>  
                </div>
                <div className="topPanelRight"></div>
              </div>
            <div className="playersectormaincounter flexrow">
                <button className="healthButton"
                  onMouseDown={this.startAdjustHealthMouse.bind(this,playerId, 1)}
                  onTouchStart={this.startAdjustHealth.bind(this,playerId, 1)}
                >
                    <img src={minus_health} alt={"Deal Player " + playerNo + " One Damage"} draggable="false" />
                </button>
                <button className={"counterButton " + counterclass} id={"player" + playerNo + "health"} onClick={this.props.showPlayerContext.bind(this, playerId)}>
                    {this.props.startingHealth - player.damage}
                </button>
                <button className="healthButton"
                 onMouseDown={this.startAdjustHealthMouse.bind(this,playerId, -1)}
                 onTouchStart={this.startAdjustHealth.bind(this,playerId, -1)} 
                >
                   <img src={plus_health} alt={"Deal Player " + playerNo + " Minus One Damage"}  draggable="false" />
                </button>
            </div>
            </div>
            <div className="flexrow commanderUsedWrapper">
              {commanderUsedPanel}
            </div>
            <div className="flexrow commanderDamageWrapper">
              {commanderDamagePanel}
            </div>
          </div>
          
        </div>
      )
    }
  }

  export default PlayerSector;
  
